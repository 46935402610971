import { useHistory } from 'react-router'
import { useCallback, useMemo } from 'react'

export function useSearchParams() {
  const history = useHistory()

  const searchParams = useMemo(() => {
    return {
      string: history.location.search.toString(),
      object: Object.fromEntries(new URLSearchParams(history.location.search)),
    }
  }, [history.location.search])

  const getParam = useCallback(
    (name, defaultValue = undefined) => {
      return (
        new URLSearchParams(history.location.search).get(name) ?? defaultValue
      )
    },
    [history.location.search],
  )

  const setParams = useCallback(
    (params = {}) => {
      history.push({
        pathname: history.location.pathname,
        search: new URLSearchParams(params).toString(),
      })
    },
    [history],
  )

  return {
    getParam,
    setParams,
    searchParams,
  }
}
