import { useEffect, useState } from 'react'
import { useSearchParams } from './useSearchParams'

export const usePagination = (data, activeSearchParams = false) => {
  const { searchParams, setParams, getParam } = useSearchParams()

  const currentPage = activeSearchParams ? getParam('page', 1) : 1

  const [pagination, setPagination] = useState({
    current_page: currentPage,
  })

  useEffect(() => {
    setPagination((state) => ({ ...state, ...data, current_page: currentPage }))
  }, [data, currentPage])

  const goToPage = (page) => {
    if (activeSearchParams) {
      setParams({ ...searchParams.object, page })
    }

    setPagination({
      ...pagination,
      current_page: page,
    })
  }

  const handleNextPage = () => {
    goToPage(Number(pagination.current_page) + 1)
  }

  const handleBackPage = () => {
    goToPage(Number(pagination.current_page) - 1)
  }

  const handleLastPage = () => {
    goToPage(pagination.last_page)
  }

  const handleFirstPage = () => {
    goToPage(1)
  }

  return {
    pagination,
    handleNextPage,
    handleBackPage,
    handleLastPage,
    handleFirstPage,
  }
}
